<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedFtpActions"
        :default-value="properties.action"
        step-label="ftp"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-checkbox
        :key="`${step.id}-isSftp`"
        v-model="isSftp"
        :label="$lang.labels.isSftp"
        :readonly="!canEdit"
        class="mt-0 pt-0"
      />
    </v-col>

    <credentials-select
      :key="`${step.id}-credentialName`"
      step-type="FTP"
      :properties="properties"
      :is-sftp="isSftp"
      step-label="ftp"
      :readonly="!canEdit"
      required
      @change="handleChange('credentialName', $event)"
    />

    <v-col v-if="properties.action === 'RENAME'" cols="12">
      <v-text-field
        :key="`${step.id}-oldPath`"
        v-model="properties.fields.oldPath"
        outlined
        dense
        :label="$lang.labels.oldPath"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="ftp-oldPath"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="properties.action === 'RENAME' ? `${step.id}-new-path` : `${step.id}-path`"
        v-model="properties.path"
        outlined
        dense
        :label="properties.action === 'RENAME' ? $lang.labels.newPath : $lang.labels.path"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="ftp-path"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        :required="isListOrDownload"
        :rules="[v => isListOrDownload ? !!v || $lang.labels.required : true, 
                 (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        :class="isListOrDownload ? 'required-asterisk' : ''"
        data-cy="ftp-targetObject"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  ActionSelect,
  CredentialsSelect
} from './components'
import StepMixin from './StepMixin'

import { FTP_ACTIONS } from './constants'

export default {
  name: 'FTPStep',
  components: {
    ActionSelect,
    CredentialsSelect
  },
  mixins: [StepMixin],
  data() {
    return {
      isSftp: true
    }
  },
  computed: {
    formattedFtpActions() {
      return FTP_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    isListOrDownload() {
      return this.properties.action === 'LIST' || this.properties.action === 'DOWNLOAD'
    }
  },
  created() {
    if (!this.properties.fields) this.properties.fields = {}
  }
}
</script>
